import React from "react"

import "./header.scss"
import logo from "../../images/bnebula-logo-new.png"
// import Navigation from "../navigation/navigation"
import { Link } from "gatsby"

const Header = (props) => (
  <header className="header">
      <div className="header__inner">
        <div className="header__logo">
          <Link to="/"><img alt="Blue Nebula Interactive Logo" src={logo} /></Link>
        </div>
        <div className="header__quote">
          <button onClick={props.openContactForm}>Contact</button>
        </div>
        {/* <Navigation /> */}
      </div>
  </header>
)

export default Header
