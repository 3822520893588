import React from 'react'

import "./cta.scss"

const Cta = (props) => {
  return (
    <div className="cta">
      <div className="cta__inner">
        <h2>Ready to build something?</h2>
        <h3>Start by telling us a little about your project.</h3>
        <button className="button" onClick={props.openContactForm}>Request a Call</button>
      </div>
    </div>
  )
}

export default Cta
