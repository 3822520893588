import React, { Component } from 'react'
// import Select from 'react-select'
import './contactForm.scss'

// const budgetOptions = [
//   { value: '$5000-10000', label: '$5,000 - $10,000'},
//   { value: '$10000-25000', label: '$10,000 - $25,000'},
//   { value: '$25000-50000', label: '$25,000 - $50,000'},
//   { value: '$50000-100000', label: '$50,000 - $100,000'},
//   { value: 'Above $100k', label: 'Above $100K'}
// ];

// const selectStyle = {
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected ? '#000' : '#333',
//     borderBottom: '1px solid #111',
//     color: '#fff'
//   }),
//   control: (provided) => ({
//     ...provided,
//     backgroundColor: '#333',
//     border: 'none',
//     marginBottom: '20px',
//     color: '#fff'
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     color: '#fff'
//   }),
//   menuList: (provided) => ({
//     ...provided,
//     backgroundColor: '#333'
//   })
// }

class ContactForm extends Component {
  render() {
    return (
      <div className="contactForm">
        <div className="contactForm__message">
          <h2>Contact Us</h2>
        </div>
        <form name="contact" action="/thank-you" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="form-item">
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" type="text" name="firstName" required />
          </div>
          <div className="form-item">
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" type="text" name="lastName" required />
          </div>
          <div className="form-item">
            <label htmlFor="email">Email</label>
            <input id="email" type="email" name="email" required />
          </div>
          <div className="form-item">
            <label htmlFor="phone">Phone #</label>
            <input id="phone" type="phone" name="phone" />
          </div>
          <div className="form-item">
            <label htmlFor="body">Tell us about your project</label>
            <textarea id="body" name="body" required></textarea>
          </div>

          {/* <Select options={budgetOptions} styles={selectStyle} placeholder="What is your Budget" /> */}

          <button type="submit">Submit</button>
        </form>
      </div>
    )
  }
}

export default ContactForm
