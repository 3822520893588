import React, { Component } from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import Flyout from "./flyout/flyout"
import ContactForm from "./contactForm/contactForm"
import Cta from "../components/cta/cta"

import "./layout.scss"

class Layout extends Component {

  state = {
    contactFormOpen: false
  }

  openContactForm = () => {
    this.setState({contactFormOpen: true});
  }

  closeContactForm = () => {
    this.setState({contactFormOpen: false});
  }

  render() {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      require("smooth-scroll")('a[href*="#"]')
    }
    return (
      <>
      <div id="top" className="page">
        <div className="main">
          <Header siteTitle="Blue Nebula Interactive" openContactForm={this.openContactForm} />
          <div className="main-content">
            {this.props.children}
            {/* <Cta openContactForm={this.openContactForm}/> */}
          </div>

          {/* <Footer /> */}
        </div>

        <Flyout isOpen={this.state.contactFormOpen} closeContactForm={this.closeContactForm}>
          <ContactForm />
        </Flyout>
      </div>
      </>
    )
  }
}

export default Layout
